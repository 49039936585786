import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyALVp3dS4JR746VDFuSCceMnTNd-VEWtAw",
    authDomain: "santa-95d5e.firebaseapp.com",
    projectId: "santa-95d5e",
    storageBucket: "santa-95d5e.appspot.com",
    messagingSenderId: "437750990819",
    appId: "1:437750990819:web:d28d103f0dd3b5a8a742d6",
    measurementId: "G-MVL13ZFW9B"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const firestore = firebase.firestore();
export const auth = firebase.auth();
