import React, { useContext } from "react";
import info from "../assets/Group 248.png";
import home from "../assets/home.png";
import msg from "../assets/msg.png";
import setting from "../assets/setting.png";
import Style from "../style/Navbar.module.css";
import { useHistory } from "react-router";
import { userContext } from '../pages/Workflow';
import { Link } from "react-router-dom";
import { ROUTES } from "../pages/Routes";
export const Navbar = (props) => {
  const { page, setPage } = useContext(userContext);
  const History = useHistory();
  const handleBack = () => {
    if (page === 0) {
      History.push("/workflowcbd");
    } else {
      setPage((prev) => prev - 1)
    }
  };
  return (
    <>
      <nav className={Style.HomeNav} onClick={handleBack}>
        {/* <div className={Style.backBtn}> */}
        <Link to={ROUTES.Selection} className={Style.backBtn}>
          {"<back"}
        </Link>
        {/* </div> */}
        <div className={Style.appIcon}>
          <div className={Style.appInfo}>
            <img src={info} alt="" />
          </div>
          <div className={Style.otherAppIcons}>
            <div className={Style.iconWrapper}>
              <img src={home} alt="" />
            </div>
            <div className={Style.iconWrapper}>
              <img src={msg} alt="" />
            </div>
            <div className={Style.iconWrapper}>
              <img src={setting} alt="" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
