import React, { useContext, useState } from "react";
import style from "../style/UniqueID.module.css";
import edit from "../assets/Vector.png";
import redDotIcon from "../assets/reddot.png";
import greenDotIcon from "../assets/greendot.png";
import penIcon from "../assets/penIcon.png";
import { Link, useHistory } from "react-router-dom";
import info from "../assets/Group 248.png";
import home from "../assets/home.png";
import msg from "../assets/msg.png";
import setting from "../assets/setting.png";
import Style from "../style/Navbar.module.css";
import "../App.css";
import { ROUTES } from "./Routes";
import { userContext } from "./Workflow";
import Callout from "../components/Callout/Callout";
import ChatWindowPopup from "../assets/chat-window-popup.png";
var randomize = require("randomatic");

const calloutData1 = {
  index: 1,
  enable: true,
  text: ["Press on the message icon for the popup window to appear."],
  bullets: false,
  direction: "rightTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "4%",
    left: "72%",
    margin: "4% 4%",
  },
};
const calloutData2 = {
  index: 2,
  enable: true,
  text: ["This is the chat popup that will appear incase of any queries."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "1%",
    left: "37%",
    margin: "1% 1%",
  },
};
const calloutData3 = {
  index: 3,
  enable: true,
  text: ["Press Continue button to go to the next page."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "64%",
    left: "70.5%",
    margin: "1% 1%",
  },
};

export const ChatWindow = () => {
  const { page, setPage: takeToNextPage } = useContext(userContext);

  const [calloutID, setCalloutID] = useState(
    localStorage.getItem("calloutID")
      ? parseInt(localStorage.getItem("calloutID"))
      : 1
  );

  const handleCallOutNextNextBtn = (id) => {
    if (id < 3) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev + 1);
        return prev + 1;
      });
    } else if (id == 3) {
      localStorage.setItem("calloutID", 1);
      takeToNextPage((prev) => prev + 1);
    }
  };
  const handleCallOutNextPrevBtn = (id) => {
    if (id <= 3) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev - 1);
        return prev - 1;
      });
    }
  };

  const [code, setCode] = useState("");
  const history = useHistory();
  function handleSubmit(e) {
    e.preventDefault();
    console.log(code);
    takeToNextPage();
    // history.push("/startworkflowcbd");
  }

  const generateRandom = (event) => {
    event.preventDefault();
    let gr = randomize("0Aa", 10);
    setCode(gr);
  };

  return (
    <>
      <div className={style.HomeContainer}>
        <div>
          <div className="chat-window-popup">
            <img src={ChatWindowPopup} alt="" />
          </div>
          <nav className={Style.HomeNav}>
            {/* <div className={Style.backBtn}> */}
            <Link to={ROUTES.Selection} className={Style.backBtn}>
              {"<back"}
            </Link>
            {/* </div> */}
            <div className={Style.appIcon}>
              <div className={Style.appInfo}>
                <img src={info} alt="" />
              </div>
              <div className={Style.otherAppIcons}>
                <div className={Style.iconWrapper}>
                  <img src={home} alt="" />
                </div>
                <div className={Style.iconWrapper}>
                  <img src={msg} alt="" />
                </div>
                <div className={Style.iconWrapper}>
                  <img src={setting} alt="" />
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className={style.Content}>
          <h1>Enter Customer ID</h1>
          <form className={style.formContent} onSubmit={handleSubmit}>
            <div className={style.formInputField}>
              <input
                id="code"
                type="text"
                value={code}
                placeholder="Enter Mobile No / Unique Code"
                autoComplete="off"
                name="code"
                onChange={(e) => setCode(e.target.value)}
              />
              <label htmlFor="code">
                <img src={edit} alt="" />
              </label>
            </div>
            <div className={style.formSubmit}>
              <span onClick={generateRandom}>Generate Unique Code</span>
              <button type="submit">Continue</button>
            </div>
          </form>
        </div>
      </div>
      {calloutID === 1 && (
        <Callout
          data={calloutData1}
          onClick={handleCallOutNextNextBtn}
          onBackClick={handleCallOutNextPrevBtn}
        />
      )}
      {calloutID === 2 && (
        <Callout
          data={calloutData2}
          onClick={handleCallOutNextNextBtn}
          onBackClick={handleCallOutNextPrevBtn}
          onBackClick={handleCallOutNextPrevBtn}
        />
      )}
      {calloutID === 3 && (
        <Callout
          data={calloutData3}
          onClick={handleCallOutNextNextBtn}
          onBackClick={handleCallOutNextPrevBtn}
        />
      )}
    </>
  );
};
