import React from 'react'
import style from "../../../style/Card/GreenCard.module.css"
function GreenCard({title,img}) {
    return (
        <>
            <fieldset className={style.cardBody}>
            <legend>{title}</legend>
            <div className={style.imgWrapper}>
                <img src={img} alt="" />
            </div>
            </fieldset>
        </>
    )
}

export default GreenCard
