import img1 from "../../../assets/package/img1.png";
import img2 from "../../../assets/package/img2.png";
import img3 from "../../../assets/package/img3.png";
import img4 from "../../../assets/package/img4.png";
import img5 from "../../../assets/package/img5.png";
export const Packages = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
];
