import './App.css';
import Routes from './pages/Routes';
import './style/loadingRing.css'

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
