import React from "react";
import style from "../../../style/Card/Standard.module.css"
export const Standard = ({ img, text }) => {
  return (
    <>
      <div className={style.CardWrapper}>
        <div className={style.imgWrapper}>
          <img src={img} alt="" />
        </div>
        <h1>Standard image 1</h1>
      </div>
    </>
  );
};
