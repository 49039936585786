import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "../style/VideoTutorial.module.css";
import { Link } from "react-router-dom";
import VideoCard from "../components/Video/VideoCard";
import VideoModal from "../components/Video/VideoModal";
import VideoComplete from "../components/Video/Complete";
import { VIDEOS_DATA } from "./VideoData";
import { UserContext } from "../Context/Auth/UserContextProvider";
import { fetchVideos } from "../Firebase/firestoreFunction";
import { ROUTES } from "./Routes";

const VideoTutorial = ({ type, title }) => {
  let countVarName = type + "_count";
  const { userData } = useContext(UserContext);
  const [isloading, setLoadingState] = useState(true);
  const [currentState, setState] = useState({
    videoData: null,
    lastVideoIndex:
      userData && userData[countVarName] ? userData[countVarName] : 0,
    video: null,
    completed: false,
    showCompletion: false,
  });

  useEffect(() => {
    fetchVideos(type).then((data) => {
      setState({
        videoData: data,
        lastVideoIndex:
          userData && userData[countVarName] ? userData[countVarName] : 0,
        video: null,
        completed: false,
        showCompletion: false,
      });
      setLoadingState(false);
    });
    //getVideosData
  }, []);

  useEffect(() => {
    if (userData) {
      if (userData[countVarName])
        setState((prev) => ({
          ...prev,
          lastVideoIndex: userData[countVarName],
        }));
    }
  }, [userData]);

  useEffect(() => {
    if (currentState.videoData) {
      if (
        currentState.lastVideoIndex >= currentState.videoData.length &&
        !currentState.completed
      ) {
        setState((prev) => ({
          ...prev,
          completed: true,
          showCompletion: true,
        }));
      }
    }
  }, [currentState]);

  let videoCount = useMemo(() => {
    return currentState.videoData ? currentState.videoData.length : 9;
  }, [currentState]);

  return (
    <>
      <div className={style.videContainer}>
        <nav>
          <Link to={ROUTES.Selection} className={style.backBtn}>
            {"<back"}
          </Link>
        </nav>
        <div className={style.videoContent}>
          <div className={style.aboutVideo}>
            <h1> {title}</h1>
          </div>
          <div className={style.videoCounts}>
            <p>
              <p>
                {currentState.lastVideoIndex >= videoCount
                  ? videoCount
                  : currentState.lastVideoIndex}
                / {videoCount}
              </p>
            </p>
            <div className={style.completeVideo}>
              <div
                className={style.process}
                style={{
                  width: `${currentState.lastVideoIndex * (100 / videoCount)}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className={style.AllVideos}>
          {isloading && (
            <div>
              <div className="lds-dual-ring"></div>
            </div>
          )}
          {currentState.videoData &&
            currentState.videoData.map((item, index) => (
              <VideoCard
                key={`${index}_videoCard`}
                currentVideoIndex={index}
                currentState={currentState}
                updateState={setState}
                videoData={item}
              />
            ))}
        </div>
        <div>
          {currentState.video && (
            <VideoModal
              currentState={currentState}
              updateState={setState}
              videoCount={videoCount}
              dbVarName={countVarName}
            />
          )}
        </div>
        <div>
          {currentState.showCompletion && (
            <VideoComplete
              updateState={setState}
              videoCount={videoCount}
              countVarName={countVarName}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VideoTutorial;
