import React, { createContext, useState, useEffect, useRef } from 'react'
import { auth } from '../../Firebase';
import { attachUserInfo, getUserInfo } from '../../Firebase/firestoreFunction';

export const UserContext = createContext();

export default function UserContextProvider(props) {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userAuth')));
    const [userPath, setUserPath] = useState(localStorage.getItem('userPath'));
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData')));
    const ListenerRef = useRef(null)

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            try {
                if (user) {
                    console.log(user.email)
                    attachUserInfo(user.email, ListenerRef.current, (error, userData) => {
                        if (error) {
                            console.log(error)
                        } else {
                            let path = `${userData.tutorial.toLowerCase()}${userData.type.toLowerCase()}`
                            localStorage.setItem('userPath', path)
                            setUserPath(path)

                            localStorage.setItem('userData', JSON.stringify(userData))
                            setUserData(userData)

                            localStorage.setItem('userAuth', JSON.stringify(user))
                            setUser(user)
                        }
                    })
                } else {
                    if (ListenerRef.current) {
                        ListenerRef.current()
                    }
                    localStorage.clear()
                    setUser(null)
                }
            } catch (error) {
                console.log(error)
            }

        });

    }, [])

    return (
        <UserContext.Provider value={{ user, userPath, userData }}>
            {props.children}
        </UserContext.Provider>
    )
}
