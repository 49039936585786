import img1 from "../../../assets/Ads on/img1.png";
import img2 from "../../../assets/Ads on/img2.png";
import img3 from "../../../assets/Ads on/img3.png";
export const AdsOn=[
    {
        id: 1,
        img: img1,
      },
      {
        id: 2,
        img: img2,
      },
      {
        id: 3,
        img: img3,
      },
]