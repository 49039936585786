import { auth } from ".";
import { updateUserInfo } from "./firestoreFunction";

export const signInWithId = (email, password) => {
    return new Promise(async (res, rej) => {
        try {
            const cred = await auth.signInWithEmailAndPassword(email, password);
            console.log(cred.user.email + " is logged in right now");
            res();
        } catch (error) {
            rej(error);
        }
    });
};
const defaultImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/djfarmademo.appspot.com/o/profileimages%2Fblank-avatar.png?alt=media&token=2af15226-9bd7-47ce-bc72-f3c1a12a0780";

export const signUpWithId = (email, password, firstName) => {
    return new Promise(async (res, rej) => {
        try {
            //console.log("going in as::::::"+ name);
            const response = await auth.createUserWithEmailAndPassword(
                email,
                password
            );
            console.log(response.user.email + " is signedUp in right now");
            await response.user.updateProfile({
                displayName: firstName,
                photoURL: defaultImageUrl,
            });
            res();
        } catch (error) {
            rej(error);
        }
    });
};

export const loadUser = async (
    email,
    password,
    inputData,
    forceCreateNew = true
) => {
    return new Promise(async (res, rej) => {
        try {
            await updateUserInfo(email, inputData);
            await signInWithId(email, password);
            res();
        } catch (err) {
            if (err.code === "auth/user-not-found" && forceCreateNew) {
                try {
                    await signUpWithId(
                        email,
                        password,
                        inputData.name
                    );
                    res();
                } catch (error) {
                    console.log(error);
                    if (error.code === "NoUserFound") {
                        rej(error);
                    }
                }
            }
            rej({
                code: err.code,
                message: err.message,
            });
        }
    });
};

export const signOut = (noRefresh) => {
    auth.signOut().then(function () {
        // window.open("https://" + document.domain, "_self");
        if (!noRefresh) {
            window.location.reload();
            window.location.href = "/";
        }
    });
};
