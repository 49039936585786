import React, { useContext, useState } from "react";
import { Navbar } from "../Navbar";
import style from "../../style/GreenScreen.module.css";
import image from "../../assets/GreenScreen1_BG 3 (1).png";

import { Footer } from "../Footer";
import Callout from "../Callout/Callout";
import { userContext } from "../../pages/Workflow";

const calloutData1 = {
  index: 1,
  enable: true,
  text: ["Click on this button to capture images."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "46%",
    left: "2%",
    margin: "4% 4%",
  },
};
const calloutData2 = {
  index: 2,
  enable: true,
  text: [
    "All the 3 Green Screen images clicked will be shown here.",
  ],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "0%",
    left: "58%",
    margin: "1% 1%",
  },
};
const calloutData3 = {
  index: 3,
  enable: true,
  text: ["Press this icon to delete the extra images if required."],
  bullets: false,
  direction: "leftTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "81%",
    left: "47%",
    margin: "1% 1%",
  },
};
const calloutData4 = {
  index: 4,
  enable: true,
  text: ["Press Continue button to go to the next page."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "74%",
    left: "75%",
    margin: "0% 0%",
  },
};

export const GreenScreen = () => {
  const { page, setPage } = useContext(userContext);
  const [calloutID, setCalloutID] = useState(
    localStorage.getItem("calloutID")
      ? parseInt(localStorage.getItem("calloutID"))
      : 1
  );

  const handleCallOutNextNextBtn = (id) => {
    if (id < 4) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev + 1);
        return prev + 1;
      });
    } else if (id == 4) {
      localStorage.setItem("calloutID", 1);
      setPage((prev) => prev + 1);
    }
  };

  const handleCallOutNextPrevBtn = (id) => {
    if (id <= 4) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev - 1);
        return prev - 1;
      });
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        <div>
          <Navbar />
        </div>
        <div className={style.Content}>
          <div className={style.LeftSection}>
            <div className={style.imageWrapper}>
              <img src={image} alt="" />
            </div>
            <div className={style.selectImg}>
              <button>Click Image</button>
            </div>
          </div>
          <div className={style.RightSection}>
            <div className={style.heading}>
              <h1>Photo Library</h1>
            </div>
            <div className={style.imgSection}>
              <div>
                <fieldset className={style.UpperImg}>
                  <legend>Green Screen Images</legend>
                  <img src={image} alt="" />
                  <img src={image} alt="" />
                  <img src={image} alt="" />
                </fieldset>
              </div>
              <div>
                <fieldset className={style.UpperImg}>
                  <legend>Extra Image</legend>
                  <img src={image} alt="" />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        {calloutID === 1 && (
          <Callout
            data={calloutData1}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 2 && (
          <Callout
            data={calloutData2}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 3 && (
          <Callout
            data={calloutData3}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 4 && (
          <Callout
            data={calloutData4}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        <Footer />
      </div>
    </>
  );
};
