import React, { useContext, useState } from "react";
import style from "../style/Home.module.css";
import edit from "../assets/Vector.png";
import downArrow from "../assets/Vector (1).png";
import { useHistory } from "react-router-dom";
import { loadUser, signOut } from "../Firebase/auth";
import { UserContext } from "../Context/Auth/UserContextProvider";
import { updateUserInfo } from "../Firebase/firestoreFunction";

const Selection = () => {
  const { user } = useContext(UserContext);
  const [Tutorial, setTutorial] = useState(false);
  const [isloading, setLoadingState] = useState(false);
  const [Type, setType] = useState(false);
  const [input, setInput] = useState({
    tutorial: "",
    type: "",
  });
  let history = useHistory();
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((pre) => {
      return { ...pre, [name]: value };
    });
  };
  const handleTutorial = (value) => {
    setInput((pre) => {
      return { ...pre, tutorial: value };
    });
    setTutorial(false);
  };

  const handleType = (value) => {
    setInput((pre) => {
      return { ...pre, type: value };
    });
    setType(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.tutorial !== "" && input.type !== "") {
      setLoadingState(true);
      await updateUserInfo(user.email, {
        tutorial: input.tutorial,
        type: input.type,
      });
      let path = `${input.tutorial.toLowerCase()}${input.type.toLowerCase()}`;
      history.push(path);
    } else {
      //   alert("Please check all input fields");
    }
  };

  return (
    <>
      <div className={style.HomeContainer}>
        <nav className={style.HomeNav}></nav>
        <div className={style.homeContent}>
          <div className={style.aboutForm}>
            <h1>Select Tutorial Type:</h1>
          </div>
          <form className={style.formContent} onSubmit={handleSubmit}>
            <div
              className={style.formInputField}
              onClick={() => setTutorial(!Tutorial)}
            >
              <div className={style.inputDrop}>
                <h1>
                  {/* {input.tutorial === "" ? "Tutorial Type" : input.tutorial} */}
                  {input.tutorial === "" ? "Tutorial Type" : input.tutorial === "Workflow" ? "App Workflow" : "Video Tutorial"}

                </h1>
              </div>
              <div className={style.inputDropLogo}>
                <img src={downArrow} alt="" />
              </div>
            </div>
            {Tutorial && (
              <div className={style.FormDropDown}>
                <div
                  className={style.dropOption}
                  onClick={() => handleTutorial("Workflow")}
                >
                  App Workflow
                </div>
                <div
                  className={style.dropOption}
                  onClick={() => handleTutorial("Tutorial")}
                >
                  Video Tutorial
                </div>
              </div>
            )}

            <div
              className={style.formInputField}
              onClick={() => setType(!Type)}
            >
              <div className={style.inputDrop}>
                <h1>{input.type === "" ? "Setup Type" : input.type}</h1>
              </div>
              <div className={style.inputDropLogo}>
                <img src={downArrow} alt="" />
              </div>
            </div>

            {Type && (
              <div className={style.FormDropDown}>
                <div
                  className={style.dropOption}
                  onClick={() => handleType("CBD")}
                >
                  CBD
                </div>
                <div
                  className={style.dropOption}
                  onClick={() => handleType("SUBURBAN")}
                >
                  SUBURBAN
                </div>
              </div>
            )}

            <div className={style.formSubmit_2Btn}>
              <button onClick={signOut}>Logout</button>

              <button
                type="submit"
                className={isloading ? "btnLoader lds-dual-ring" : ""}
                disabled={input.tutorial === "" || input.type === ""}
              >
                {isloading ? "" : "Continue"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Selection;
