import React, { useContext } from "react";
import style from "../../style/VideoModal.module.css";
import ReactPlayer from "react-player";
import { AiOutlineCheck } from "react-icons/ai";
import {
  updateStepCount,
  updateUserInfo,
} from "../../Firebase/firestoreFunction";
import { UserContext } from "../../Context/Auth/UserContextProvider";
function VideoModal({ currentState, updateState, videoCount, dbVarName }) {
  const { user } = useContext(UserContext);

  const handleModal = () => {
    if (currentState.lastVideoIndex <= currentState.video.index) {
      updateStepCount(user.email, dbVarName, currentState.video.index + 1);
      updateState((prev) => {
        return {
          ...prev,
          video: null,
          lastVideoIndex: currentState.video.index + 1,
        };
      });
    }
  };

  const closeModal = () => {
    updateState((prev) => {
      return {
        ...prev,
        video: null,
      };
    });
  };

  return (
    <>
      <div className={style.videoOverlay} onClick={closeModal}></div>
      <div className={style.videoPop}>
        <div className={style.content}>
          <div className={style.videoCounts}>
            <p>
              {currentState.lastVideoIndex >= videoCount
                ? videoCount
                : currentState.lastVideoIndex}
              / {videoCount}
            </p>
            <div className={style.completeVideo}>
              <div
                className={style.process}
                style={{
                  width: `${currentState.lastVideoIndex * (100 / videoCount)}%`,
                }}
              ></div>
            </div>
          </div>
          {currentState.video.index >= currentState.lastVideoIndex && (
            <button className={style.complete} onClick={handleModal}>
              <span>
                <AiOutlineCheck />
              </span>
              Mark as completed
            </button>
          )}
        </div>
        <div className={style.videoPlayer}>
          <ReactPlayer
            url={currentState.video.link}
            width="60rem"
            height="40rem"
            controls={true}
          />
        </div>
        <div className={style.videoName}>
          <h1>{currentState.video.name}</h1>
        </div>
      </div>
    </>
  );
}

export default VideoModal;
