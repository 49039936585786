import React, { useContext, useState } from "react";
import style from "../../style/AllImagePack.module.css";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { Standard } from "./Cards/Standard";
import standImg from "../../assets/Rectangle 303.png";
import standImg2 from "../../assets/Group 357.png";
import essImage from "../../assets/image 4.png";
import essImage2 from "../../assets/Group 261.png";
import essImage3 from "../../assets/magic1.png";
import essImage4 from "../../assets/magic2.png";
import greenImg1 from "../../assets/GreenScreen1_BG 1.png";
import greenImg2 from "../../assets/GreenScreen2_BG 2.png";
import greenImg3 from "../../assets/Group 358.png";
import EssentialCard from "./Cards/EssentialCard";
import GreenCard from "./Cards/GreenCard";
import jumboImg from "../../assets/jumbo.png";
import Callout from "../Callout/Callout";
import { userContext } from "../../pages/Workflow";

const calloutData1 = {
  index: 1,
  enable: true,
  text: ["Scroll top to down to view all the images clicked in the Santa & Green Screen booth."],
  bullets: false,
  direction: "leftTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "16%",
    left: "13%",
    margin: "4% 4%",
  },
};
const calloutData2 = {
  index: 2,
  enable: true,
  text: ["These are all the final package images that are being created from the raw images clicked."],
  bullets: false,
  direction: "rightTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "29%",
    left: "17%",
    margin: "1% 1%",
  },
};
const calloutData3 = {
  index: 3,
  enable: true,
  text: ["Scroll horizontal left to right to view all the packages."],
  bullets: false,
  direction: "leftTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "84%",
    left: "32%",
    margin: "1% 1%",
  },
};
const calloutData4 = {
  index: 4,
  enable: true,
  text: ["Press Continue button to go to the next page."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "74%",
    left: "77%",
    margin: "0% 0%",
  },
};

const AllImagePack = () => {
  const { page, setPage } = useContext(userContext);
  const [calloutID, setCalloutID] = useState(
    localStorage.getItem("calloutID")
      ? parseInt(localStorage.getItem("calloutID"))
      : 1
  );

  const handleCallOutNextNextBtn = (id) => {
    if (id < 4) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev + 1);
        return prev + 1;
      });
    } else if (id == 4) {
      localStorage.setItem("calloutID", 1);
      setPage((prev) => prev + 1);
    }
  };

  const handleCallOutNextPrevBtn = (id) => {
    if (id <= 4) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev - 1);
        return prev - 1;
      });
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        <div>
          <Navbar />
        </div>
        <div className={style.Content}>
          <div className={style.standSection}>
            <Standard img={standImg} text="Standard image 1" />
            <Standard img={standImg} text="Standard image 1" />
            <Standard img={standImg} text="Standard image 1" />
            <Standard img={standImg2} text="Standard image 1" />
          </div>
          <div className={style.otherSections}>
            <div className={style.essentialSection}>
              <div className={style.heading}>
                <h1>Essential Pack</h1>
              </div>
              <div className={style.cardWrapper}>
                <EssentialCard title="Standard Santa" img={essImage} />
                <EssentialCard title="Mini Print" img={essImage2} />
                <EssentialCard title="Magic Photo 1" img={essImage3} />
                <EssentialCard title="Magic Photo 2" img={essImage4} />
              </div>
            </div>
            <div className={style.GreenSection}>
              <div className={style.GreenCardWrapper}>
                <GreenCard title="Green Screen 1" img={greenImg1} />
                <GreenCard title="Green Screen 2" img={greenImg2} />
                <GreenCard title="Green Screen 3" img={greenImg3} />
              </div>
            </div>
            <div className={style.jumboSection}>
              <img src={jumboImg} alt="" />
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
        {calloutID === 1 && (
          <Callout
            data={calloutData1}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 2 && (
          <Callout
            data={calloutData2}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 3 && (
          <Callout
            data={calloutData3}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 4 && (
          <Callout
            data={calloutData4}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
      </div>
    </>
  );
};

export default AllImagePack;
