import React, { useContext, useState } from "react";
import style from "../../style/PackagePage.module.css";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { AdsOn } from "./Data/AdsOn";
import { Packages } from "./Data/Packages";
import AdsImg1 from "../../assets/Ads on/img4.png";
import AdsImg2 from "../../assets/Ads on/img5.png";
import extraImg1 from "../../assets/extra/img1.png";
import modalImage from "../../assets/package/modalImage.png";
import extraImg2 from "../../assets/extra/img2.png";
import Callout from "../Callout/Callout";
import { userContext } from "../../pages/Workflow";

const calloutData1 = {
  index: 1,
  enable: true,
  text: ["Toggle the icon to view the package."],
  bullets: false,
  direction: "leftTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "19%",
    left: "12%",
    margin: "4% 4%",
  },
};
const calloutData2 = {
  index: 2,
  enable: true,
  text: ["This shows the amount of the package."],
  bullets: false,
  direction: "leftTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "46%",
    left: "19%",
    margin: "1% 1%",
  },
};
const calloutData3 = {
  index: 3,
  enable: true,
  text: ["This shows the quantity of the selected package."],
  bullets: false,
  direction: "rightTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "24%",
    left: "40%",
    margin: "1% 1%",
  },
};
const calloutData4 = {
  index: 4,
  enable: true,
  text: ["This shows the total amount of all the elements selected to purchase."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "74%",
    left: "64%",
    margin: "0% 0%",
  },
};
const calloutData5 = {
  index: 5,
  enable: true,
  text: ["Press Continue button to go to the next page."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "74%",
    left: "75%",
    margin: "0% 0%",
  },
};
const calloutData6 = {
  index: 6,
  enable: true,
  text: ["Press OK once the payment has been made by the customer so that you can print their pacakges."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "38%",
    left: "37%",
    margin: "0% 0%",
  },
};

export const PackagePage = ({ withModal }) => {
  const { page, setPage } = useContext(userContext);

  const [calloutID, setCalloutID] = useState(
    localStorage.getItem("calloutID")
      ? parseInt(localStorage.getItem("calloutID"))
      : 1
  );

  const handleCallOutNextNextBtn = (id) => {
    if (id < 5) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev + 1);
        return prev + 1;
      });
    } else if (id == 5) {
      localStorage.setItem("calloutID", 1);
      setPage((prev) => prev + 1);
    }
  };
  const handleCallOutNextNextBtn2 = (id) => {
    if (id < 1) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev + 1);
        return prev + 1;
      });
    } else if (id == 1) {
      localStorage.setItem("calloutID", 1);
      setPage((prev) => prev + 1);
    }
  };

  const handleCallOutNextPrevBtn = (id) => {
    if (id <= 5) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev - 1);
        return prev - 1;
      });
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        <div>
          <Navbar />
        </div>
        <div className={style.content}>
          <div className={style.container}>
            <div className={style.Heading}>
              <h1>Packages</h1>
            </div>
            <div>
              {Packages.map((item) => (
                <div key={item.id} className={style.ImageWrapper}>
                  <img src={item.img} alt="" />
                </div>
              ))}
            </div>
          </div>
          <div className={style.container}>
            <div className={style.Heading}>
              <h1>Ads-Ons</h1>
            </div>
            <div>
              {AdsOn.map((item) => (
                <div key={item.id} className={style.ImageWrapper}>
                  <img src={item.img} alt="" />
                </div>
              ))}
              <div className={style.otherAdsImage}>
                <div className={style.ImageWrapper}>
                  <img src={AdsImg1} alt="" />
                </div>
                <div className={style.ImageWrapper}>
                  <img src={AdsImg2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={style.container}>
            <div className={style.Heading}>
              <h1>Extras</h1>
            </div>
            <div>
              <div className={style.ImageWrapper}>
                <img src={extraImg1} alt="" />
              </div>
              <div className={style.ImageWrapper}>
                <img src={extraImg2} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer showPrice={true} />
        </div>
        {!withModal && calloutID === 1 && (
          <Callout
            data={calloutData1}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {!withModal && calloutID === 2 && (
          <Callout
            data={calloutData2}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {!withModal && calloutID === 3 && (
          <Callout
            data={calloutData3}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {!withModal && calloutID === 4 && (
          <Callout
            data={calloutData4}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {!withModal && calloutID === 5 && (
          <Callout
            data={calloutData5}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
      </div>

      {withModal && (
        <>
          <div className={style.extraModal} />
          <div className={style.extraModalContainer}>
            <img src={modalImage} alt="" />
          </div>
          {
            <Callout
              data={calloutData6}
              onClick={handleCallOutNextNextBtn2}
              onBackClick={handleCallOutNextPrevBtn}
            />
          }
        </>
      )}
    </>
  );
};
