import React, { useState, createContext, useContext, useEffect } from "react";
import AllImagePack from "../components/WorkflowCBD/AllImagePack";
import { PackagePage } from "../components/WorkflowCBD/PackagePage";
import { GreenScreen } from "../components/WorkflowCBD/GreenScreen";
import { ImageSelection } from "../components/WorkflowCBD/ImageSelection";
import { UniqueId } from "./UniqueId";
import { ChatWindow } from "./ChatWindow";
import WorkflowComplete from "../components/WorkflowCBD/WorkflowComplete";
import { UserContext } from "../Context/Auth/UserContextProvider";
import { updateStepCount } from "../Firebase/firestoreFunction";
import { GreenScreenUrban } from "../components/WorkflowCBD/GreenScreenUrban";
export const userContext = createContext();
export const Workflow = ({ type }) => {
  let countVarName = type + "_count";

  const { user, userData } = useContext(UserContext);
  const [currentState, UpdateState] = useState({
    id: 0,
    complete: false,
  });

  useEffect(() => {
    if (userData) {
      if (userData[countVarName])
        UpdateState((prev) => ({
          ...prev,
          id: userData[countVarName],
        }));
    }
  }, [userData]);

  const updatePage = () => {
    UpdateState((prev) => {
      if (prev.id < 5 && type === "Workflowcbd") {
        updateStepCount(user.email, countVarName, prev.id + 1);
        return {
          id: prev.id + 1,
          complete: false,
        };
      } else if (prev.id === 5 && type === "Workflowcbd") {
        return {
          id: 5,
          complete: true,
        };
      } else if (prev.id < 4 && type === "workflowsuburban") {
        updateStepCount(user.email, countVarName, prev.id + 1);
        return {
          id: prev.id + 1,
          complete: false,
        };
      } else if (prev.id === 4 && type === "workflowsuburban") {
        return {
          id: 4,
          complete: true,
        };
      }
    });
  };

  const resetPage = () => {
    updateStepCount(user.email, countVarName, 0, true);
    UpdateState({
      id: 0,
      complete: false,
    });
  };

  return (
    <>
      <userContext.Provider
        value={{ page: currentState.id, setPage: updatePage }}
      >
        {currentState.complete && <WorkflowComplete resetFun={resetPage} />}
        <SwitchToPage id={currentState.id} type={type} />
      </userContext.Provider>
    </>
  );
};

export const SwitchToPage = ({ id, type }) => {
  if (type === "Workflowcbd") {
    switch (id) {
      case 0:
        return <UniqueId />;
      case 1:
        return <ChatWindow />;
      case 2:
        return <GreenScreen />;
      case 3:
        return <ImageSelection />;
      case 4:
        return <AllImagePack />;
      case 5:
        return <PackagePage withModal={false} />;
      case 6:
        return <PackagePage withModal={true} />;

      default:
        return null;
    }
  } else if (type === "workflowsuburban") {
    switch (id) {
      case 0:
        return <UniqueId />;
      case 1:
        return <ChatWindow />;
      case 2:
        return <GreenScreenUrban />;
      case 3:
        return <AllImagePack />;
      case 4:
        return <PackagePage withModal={false} />;
      case 5:
        return <PackagePage withModal={true} />;

      default:
        return null;
    }
  }
};
