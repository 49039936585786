import React from "react";
import style from "../../style/VideoCard.module.css";
import playBtn from "../../assets/Group 313.png";
// import cardImg from "../../assets/Group 354.png";
const VideoCard = ({ updateState, videoData, currentVideoIndex, currentState }) => {

  const handleVideo = () => {
    updateState((pev) =>
    ({
      ...pev,
      video: {
        ...videoData,
        index: currentVideoIndex
      },
    })
    );
  };

  return (
    <>
      <div className={`${style.videoWrapper} ${currentVideoIndex <= currentState.lastVideoIndex && style.videoWrapper_active}`} onClick={handleVideo}>
        <div className={style.cardImage}>
          <img src={videoData.imageLink} alt="" />
        </div>
        {
          currentVideoIndex <= currentState.lastVideoIndex &&
          <div className={style.playBTN}>
            <img src={playBtn} alt="" />
          </div>
        }
        <div className={style.CardTitle}>
          <h1>{videoData.name}</h1>
          {/* <p>{videoData.dur}</p> */}
        </div>
        {currentState.lastVideoIndex < currentVideoIndex && <div className={style.lockedVideos}></div>}
      </div>
    </>
  );
};

export default VideoCard;
