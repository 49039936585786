import React, { useContext } from "react";
import style from "../style/GreenScreen.module.css";
import { userContext } from "../pages/Workflow";
export const Footer = ({ showPrice }) => {
  const { page, setPage } = useContext(userContext);
  const handlePage = () => {
    // if(page<0){
    setPage((prev) => prev + 1);
    // }
  };
  return (
    <>
      <div className={style.footer}>
        {showPrice && <div className={style.price}>Total Price <b>$ 45.45</b></div>}
        <button disabled={true} onClick={handlePage}>
          Continue
        </button>
      </div>
    </>
  );
};
