import React, { useState } from "react";
import style from "../style/Home.module.css";
import edit from "../assets/Vector.png";
import downArrow from "../assets/Vector (1).png";
import { useHistory } from "react-router-dom";
import { loadUser } from "../Firebase/auth";
const Home = () => {
  const [Tutorial, setTutorial] = useState(false);
  const [isloading, setLoadingState] = useState(false);
  const [Type, setType] = useState(false);
  const [input, setInput] = useState({
    name: "",
    email: "",
    tutorial: "",
    type: "",
  });
  let history = useHistory();
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((pre) => {
      return { ...pre, [name]: value };
    });
  };
  const handleTutorial = (value) => {
    setInput((pre) => {
      return { ...pre, tutorial: value };
    });
    setTutorial(false);
  };

  const handleType = (value) => {
    setInput((pre) => {
      return { ...pre, type: value };
    });
    setType(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (input.tutorial !== "" && input.type !== "") {
        setLoadingState(true)
        console.log(input);
        //register user
        await loadUser(
          input.email.toLowerCase(),
          `1234567890`,
          {
            tutorial: input.tutorial,
            type: input.type,
          },
          true
        );

        setInput({
          name: "",
          email: "",
          tutorial: "",
          type: "",
        });
        // let path = `${input.tutorial.toLowerCase()}${input.type.toLowerCase()}`
        // console.log(path)
        // history.push(path);
      } else {
        // alert("Please check all input fields");
      }
    } catch (error) {
      setLoadingState(false)
    }
  };

  return (
    <>
      <div className={style.HomeContainer}>
        <nav className={style.HomeNav}></nav>
        <div className={style.homeContent}>
          <div className={style.aboutForm}>
            <h1>Tutorial Registration</h1>
            <p>Please fill the following details to register:</p>
          </div>
          <form className={style.formContent} onSubmit={handleSubmit}>
            <div className={style.formInputField}>
              <input
                id="inputName"
                value={input.name}
                type="text"
                placeholder="Name"
                autoComplete="off"
                name="name"
                onChange={handleInput}
                required
              />
              <label htmlFor="inputName">
                <img src={edit} alt="" />
              </label>
            </div>
            <div className={style.formInputField}>
              <input
                id="inputEmail"
                type="email"
                value={input.email}
                placeholder="Enter your email address"
                autoComplete="off"
                name="email"
                required
                onChange={handleInput}
              />
              <label htmlFor="inputEmail">
                <img src={edit} alt="" />
              </label>
            </div>

            <div
              className={style.formInputField}
              onClick={() => setTutorial(!Tutorial)}
            >
              <div className={style.inputDrop}>
                <h1>
                  {input.tutorial === "" ? "Tutorial Type" : input.tutorial === "Workflow" ? "App Workflow" : "Video Tutorial"}
                </h1>
              </div>
              <div className={style.inputDropLogo}>
                <img src={downArrow} alt="" />
              </div>
            </div>
            {Tutorial && (
              <div className={style.FormDropDown}>
                <div
                  className={style.dropOption}
                  onClick={() => handleTutorial("Workflow")}
                >
                  App Workflow
                </div>
                <div
                  className={style.dropOption}
                  onClick={() => handleTutorial("Tutorial")}
                >
                  Video Tutorial
                </div>
              </div>
            )}

            <div
              className={style.formInputField}
              onClick={() => setType(!Type)}
            >
              <div className={style.inputDrop}>
                <h1>{input.type === "" ? "Setup Type" : input.type}</h1>
              </div>
              <div className={style.inputDropLogo}>
                <img src={downArrow} alt="" />
              </div>
            </div>

            {Type && (
              <div className={style.FormDropDown}>
                <div
                  className={style.dropOption}
                  onClick={() => handleType("CBD")}
                >
                  CBD
                </div>
                <div
                  className={style.dropOption}
                  onClick={() => handleType("SUBURBAN")}
                >
                  SUBURBAN
                </div>
              </div>
            )}

            <div className={style.formSubmit}>
              <button
                type="submit"
                className={isloading ? "btnLoader lds-dual-ring" : ""}
                disabled={
                  input.tutorial === "" ||
                  input.type === "" ||
                  input.name === "" ||
                  input.email === ""
                }
              >
                {
                  isloading ?
                    ''
                    :
                    'Continue'
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Home;
