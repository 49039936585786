import React, { useContext, useState } from "react";
import { Navbar } from "../Navbar";
import style from "../../style/GreenScreen.module.css";
import image from "../../assets/GreenScreenUrban.png";
import upperimg from "../../assets/greencardUpper.png";
import greencardMiddle1 from "../../assets/greencardMiddle1.png";
import greencardMiddle2 from "../../assets/greencardMiddle2.png";

import cardImg from "../../assets/greenurbanwithdel.png";

import { Footer } from "../Footer";
import Callout from "../Callout/Callout";
import { userContext } from "../../pages/Workflow";

const calloutData1 = {
  index: 1,
  enable: true,
  text: ["Click Image to take capture"],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "41%",
    left: "2%",
    margin: "4% 4%",
  },
};
const calloutData2 = {
  index: 2,
  enable: true,
  text: ["You can retake your image here."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "10%",
    left: "61.5%",
    margin: "1% 1%",
  },
};
const calloutData3 = {
  index: 3,
  enable: true,
  text: ["You can delete your images form here."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "51%",
    left: "60.5%",
    margin: "1% 1%",
  },
};
const calloutData4 = {
  index: 4,
  enable: true,
  text: ["Continue button will get you to the next page."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "74%",
    left: "75%",
    margin: "0% 0%",
  },
};

export const GreenScreenUrban = () => {
  const { page, setPage } = useContext(userContext);
  const [calloutID, setCalloutID] = useState(
    localStorage.getItem("calloutID")
      ? parseInt(localStorage.getItem("calloutID"))
      : 1
  );

  const handleCallOutNextNextBtn = (id) => {
    if (id < 4) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev + 1);
        return prev + 1;
      });
    } else if (id == 4) {
      localStorage.setItem("calloutID", 1);
      setPage((prev) => prev + 1);
    }
  };

  const handleCallOutNextPrevBtn = (id) => {
    if (id <= 4) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev - 1);
        return prev - 1;
      });
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        <div>
          <Navbar />
        </div>
        <div className={style.Content}>
          <div className={style.LeftSection}>
            {/* className={style.imageWrapper} */}
            <div>
              <img src={image} alt="" />
            </div>
            <div className={style.selectImg}>
              <button>Click Image</button>
            </div>
          </div>
          <div className={style.RightSection}>
            <div className={style.heading}>
              <h1>Santa Images</h1>
            </div>
            <div className={style.imgSection}>
              <div>
                <div className={style.UpperIm2}>
                  {/* <legend
                    style={{
                      fontSize: "2rem",
                      texttransform: "capitalize",
                      fontWeight: "bold",
                      padding: "0 2rem",
                    }}
                  >
                    Green Screen Images
                  </legend> */}
                  <img src={upperimg} alt="" />
                  <img src={upperimg} alt="" />
                  <img src={upperimg} alt="" />
                </div>
              </div>
              <div>
                <fieldset className={style.UpperIm2}>
                  <legend
                    style={{
                      fontSize: "2rem",
                      texttransform: "capitalize",
                      fontWeight: "bold",
                      padding: "0 2rem",
                    }}
                  >
                    Magic Images
                  </legend>
                  <img src={greencardMiddle1} alt="" />
                  <img src={greencardMiddle2} alt="" />
                </fieldset>
              </div>
              <div>
                <fieldset className={style.UpperIm2}>
                  <legend
                    style={{
                      fontSize: "2rem",
                      texttransform: "capitalize",
                      fontWeight: "bold",
                      padding: "0 2rem",
                    }}
                  >
                    Jumbo/ GIF Images
                  </legend>
                  <img src={cardImg} alt="" />
                  <img src={cardImg} alt="" />
                  <img src={cardImg} alt="" />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        {calloutID === 1 && (
          <Callout
            data={calloutData1}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 2 && (
          <Callout
            data={calloutData2}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 3 && (
          <Callout
            data={calloutData3}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 4 && (
          <Callout
            data={calloutData4}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        <Footer />
      </div>
    </>
  );
};
