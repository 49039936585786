import classes from "./Callout.module.css";

const Callout = ({ data, onClick, onBackClick }) => {
  return (
    data["enable"] && (
      <div className={classes["callouts__container"]} style={data.style}>
        <div
          className={`${classes["callouts--triangle"]} ${
            classes[`callouts--${data.direction}`]
          }`}
        >
          <div className={classes["callout__index"]}>{data.index}</div>
          {data["text"].map((txt, inde) => (
            <div
              key={inde}
              className={classes["callout__text"]}
              style={{
                gridArea: `${inde + 2} / ${2} / ${inde + 3} / ${inde + 3}`,
                marginBottom: data.bullets && "1rem",
              }}
            >
              {data.topIcon !== "" && <img src={data.topIcon} alt="" />}
              <div
                style={{
                  display: data.bullets && "grid",
                  gridTemplateColumns: "max-content 1fr",
                }}
              >
                {data.bullets && (
                  <div className={classes["callout_bullet"]}></div>
                )}
                {txt}
              </div>
            </div>
          ))}
          {data.index > 1 && (
            <div
              onClick={() => onBackClick(parseInt(data.index))}
              style={{
                gridArea: `${data["text"].length + 2} / ${2} / ${
                  data["text"].length + 3
                } / ${data["text"].length + 3}`,

                borderBottom: "1px solid #787878",
                cursor: "pointer",
                width: "max-content",
              }}
            >
              {console.log("object", data.index)}
              Prev
            </div>
          )}
          <div
            onClick={() => onClick(data.index)}
            className={classes["callout__btn"]}
            style={{
              gridArea: `${data["text"].length + 2} / ${3} / ${
                data["text"].length + 3
              } / ${data["text"].length + 3}`,
            }}
          >
            Next
            <span className={classes["nextbtn__icon"]}>
              <svg
                width="4"
                height="9"
                viewBox="0 0 4 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.663692 8.00216C0.749076 8.00245 0.83344 7.9836 0.910584 7.94701C0.987729 7.91041 1.05569 7.857 1.10949 7.79069L3.86998 4.3615C3.95405 4.25923 4 4.13096 4 3.99858C4 3.8662 3.95405 3.73792 3.86998 3.63566L1.01233 0.206467C0.915315 0.0897508 0.775912 0.0163522 0.624784 0.00241844C0.473656 -0.0115153 0.323183 0.035157 0.206467 0.132168C0.0897505 0.229179 0.0163522 0.368582 0.00241828 0.51971C-0.0115156 0.670838 0.0351567 0.821311 0.132168 0.938027L2.68691 4.00144L0.217898 7.06485C0.148009 7.14874 0.103614 7.25089 0.0899663 7.35923C0.0763183 7.46756 0.0939882 7.57754 0.140886 7.67614C0.187783 7.77475 0.261945 7.85785 0.354596 7.91563C0.447248 7.9734 0.55451 8.00343 0.663692 8.00216Z"
                  fill="#FAFAFA"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default Callout;
