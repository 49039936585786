import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import UnprotectedRoute from "../components/UnprotectedRoute";
import Home from "./Home";
import Selection from "./Selection";
import { UniqueId } from "./UniqueId";
import VideoTutorial from "./VideoTutorial";
import { Workflow } from "./Workflow";

export const ROUTES = {
  Root: "/",
  Tutorialcbd: "/tutorialcbd",
  Tutorialsuburban: "/tutorialsuburban",
  Workflowcbd: "/workflowcbd",
  Startworkflowcbd: "/startworkflowcbd",
  workflowsuburban: "/workflowsuburban",
  Selection: "/selection"
};

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute redirectTo={ROUTES.Root} path={ROUTES.Tutorialcbd}>
            <VideoTutorial
              title={"CBD - Video Tutorials"}
              type={"tutorialcbd"}
            />
          </ProtectedRoute>
          <ProtectedRoute redirectTo={ROUTES.Root} path={ROUTES.Tutorialsuburban}>
            <VideoTutorial
              title={"SUBURBAN - Video Tutorials"}
              type={"tutorialsuburban"}
            />
          </ProtectedRoute>

          <ProtectedRoute redirectTo={ROUTES.Root} path={ROUTES.Workflowcbd}>
            <Workflow
              type={"Workflowcbd"} />
          </ProtectedRoute>

          <ProtectedRoute redirectTo={ROUTES.Root} path={ROUTES.workflowsuburban}>
            <Workflow
              type={"workflowsuburban"} />
          </ProtectedRoute>


          <ProtectedRoute redirectTo={ROUTES.Root} path={ROUTES.Selection}>
            <Selection />
          </ProtectedRoute>

          <UnprotectedRoute redirectTo={ROUTES.Workflowcbd} path={ROUTES.Root}>
            <Home />
          </UnprotectedRoute>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
