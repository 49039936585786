import { firestore } from ".";

export const FIRESTORE_CONST = {
  COLLECTIONS: {
    userInfo: "userInfo",
    tutorialData: "tutorialData",
  },
};

export const updateUserInfo = (email, data) => {
  return new Promise(async (res, rej) => {
    try {
      if (!email) {
        throw { code: "notValid", message: "email id not valid" };
      }
      email = email.toLowerCase();
      let docRef = firestore
        .collection(FIRESTORE_CONST.COLLECTIONS.userInfo)
        .doc(email);
      await firestore.runTransaction(async (transaction) => {
        let doc = await transaction.get(docRef);
        if (doc.exists) {
          return transaction.update(docRef, data);
        } else {
          return transaction.set(docRef, data);
        }
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const updateStepCount = (
  email,
  paramName,
  paramValue,
  allowForceUpdate = false
) => {
  return new Promise(async (res, rej) => {
    try {
      if (!email) {
        throw { code: "notValid", message: "email id not valid" };
      }
      email = email.toLowerCase();
      let docRef = firestore
        .collection(FIRESTORE_CONST.COLLECTIONS.userInfo)
        .doc(email);
      await firestore.runTransaction(async (transaction) => {
        let newData = {
          [paramName]: paramValue,
        };
        let doc = await transaction.get(docRef);
        if (doc.exists) {
          let prevValue = doc.data()[paramName];
          if (prevValue) {
            if (allowForceUpdate) {
              return transaction.update(docRef, newData);
            } else {
              if (paramValue > prevValue) {
                return transaction.update(docRef, newData);
              } else {
                return;
              }
            }
          } else {
            return transaction.update(docRef, newData);
          }
        } else {
          return transaction.set(docRef, newData);
        }
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const getUserInfo = (email) => {
  return new Promise(async (res, rej) => {
    try {
      if (!email) {
        throw { code: "notValid", message: "email id not valid" };
      }
      email = email.toLowerCase();
      let doc = await firestore
        .collection(FIRESTORE_CONST.COLLECTIONS.userInfo)
        .doc(email)
        .get();
      if (doc.exists) {
        res(doc.data());
      } else {
        throw { code: "NoUserFound", message: "No User Data found" };
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
};

export const attachUserInfo = (email, listnerRef, cb) => {
  if (!email) {
    if (cb) {
      cb({ code: "notValid", message: "email id not valid" });
    }
  }
  email = email.toLowerCase();
  listnerRef = firestore
    .collection(FIRESTORE_CONST.COLLECTIONS.userInfo)
    .doc(email)
    .onSnapshot((doc) => {
      if (doc.exists) {
        if (cb) {
          cb(null, doc.data());
        }
      }
    });
};

export const fetchVideos = (docName) => {
  return new Promise(async (res, rej) => {
    try {
      if (!docName) {
        throw { code: "notValid", message: "docName id not valid" };
      }
      let doc = await firestore
        .collection(FIRESTORE_CONST.COLLECTIONS.tutorialData)
        .doc(docName)
        .get();
      if (doc.exists) {
        res(doc.data().data);
      } else {
        throw { code: "NoUserFound", message: "No User Data found" };
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
};

export const addVideos = (docName, data) => {
  return new Promise(async (res, rej) => {
    try {
      if (!docName) {
        throw { code: "notValid", message: "docName id not valid" };
      }
      await firestore
        .collection(FIRESTORE_CONST.COLLECTIONS.tutorialData)
        .doc(docName)
        .update({
          data,
        });
      res();
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
};
export const docReset = (docID, data) => {
  return new Promise(async (res, rej) => {
    try {
      if (!docID) {
        throw { code: "notValid", message: "docName id not valid" };
      }
      await firestore
        .collection(FIRESTORE_CONST.COLLECTIONS.userInfo)
        .doc(docID)
        .update(data);
      // if (!docName) {
      //     throw { code: "notValid", message: "docName id not valid" }
      // }
      // await firestore.collection(FIRESTORE_CONST.COLLECTIONS.tutorialData).doc(docName).update({
      //     data
      // })
      res(true);
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
};
