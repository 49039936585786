import React, { useContext, useState } from "react";
import style from "../../style/VideoComplete.module.css";
import close from "../../assets/Group 355.png";
import complete from "../../assets/Group 356.png";
import { ROUTES } from "../../pages/Routes";
import { Link } from "react-router-dom";
import { docReset } from "../../Firebase/firestoreFunction";
import { UserContext } from "../../Context/Auth/UserContextProvider";

const Complete = ({ updateState, videoCount, countVarName }) => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleModalClosed = () => {
    updateState((prev) => ({
      ...prev,
      showCompletion: false,
    }));
  };

  const handleReset = () => {
    setIsLoading(true);
    let data = {
      [countVarName]: 0,
    };
    docReset(user.email, data).then((res) => {
      if (res) {
        updateState((prev) => ({
          ...prev,
          lastVideoIndex: 0,
          showCompletion: false,
        }));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className={style.videoOverlay} onClick={handleModalClosed}></div>
      <div className={style.Content}>
        <nav>
          <img src={close} alt="" onClick={handleModalClosed} />
        </nav>
        <div className={style.header}>
          <h1>Videos Completed</h1>
        </div>
        <div className={style.completeLogo}>
          <img src={complete} alt="" />
        </div>
        <div className={style.footer}>
          <h1>
            You Have Completed {videoCount} out of {videoCount} videos
          </h1>
          <h1>
            {videoCount}/{videoCount}
          </h1>
        </div>
        <div className={style.stackFooter}>
          <Link to={ROUTES.Selection} className={style.backBtn}>
            BACK TO SELECTION
          </Link>
          <div
            onClick={handleReset}
            className={
              isLoading
                ? `btnLoader lds-dual-ring ${style.backBtn}`
                : style.backBtn
            }
            style={{
              minWidth: "13rem",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            {isLoading ? "" : "RESET"}
          </div>
        </div>
      </div>
    </>
  );
};

export default Complete;
