import React, { useContext, useState } from "react";
import { Navbar } from "../Navbar";
import style from "../../style/ImageSelection.module.css";
import image from "../../assets/GreenScreen1_BG 7.png";
import { userContext } from "../../pages/Workflow";
import Callout from "../Callout/Callout";

const calloutData1 = {
  index: 1,
  enable: true,
  text: [
    "Use this option to crop the image from top left portion.",
    "Use this option to crop the image from bottom right portion.",
    "You can move the image & place it accordingly.",
  ],
  bullets: true,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "26%",
    left: "11%",
    margin: "4% 4%",
  },
};
const calloutData2 = {
  index: 2,
  enable: true,
  text: ["Select the image layer to be edited."],
  bullets: false,
  direction: "leftTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "34%",
    left: "69%",
    margin: "1% 1%",
  },
};
const calloutData3 = {
  index: 3,
  enable: true,
  text: ["This button resets all the edits made to your photo."],
  bullets: false,
  direction: "leftBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "70%",
    left: "0.5%",
    margin: "1% 1%",
  },
};
const calloutData4 = {
  index: 4,
  enable: true,
  text: ["This button saves your edits made to your photo."],
  bullets: false,
  direction: "leftTop", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "86%",
    left: "45%",
    margin: "0% 0%",
  },
};
const calloutData5 = {
  index: 5,
  enable: true,
  text: ["This button closes the advance edit options for your photo."],
  bullets: false,
  direction: "rightBottom", //rightTop, rightBottom , leftTop, leftBottom
  topIcon: "",
  style: {
    position: "absolute",
    top: "70%",
    left: "76%",
    margin: "0% 0%",
  },
};

export const ImageSelection = () => {
  const [calloutID, setCalloutID] = useState(
    localStorage.getItem("calloutID")
      ? parseInt(localStorage.getItem("calloutID"))
      : 1
  );

  const { page, setPage } = useContext(userContext);

  const handlePage = () => {
    // if(page<0){
    setPage((prev) => prev + 1);
    // }
  };

  const handleCallOutNextNextBtn = (id) => {
    if (id < 5) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev + 1);
        return prev + 1;
      });
    } else if (id == 5) {
      localStorage.setItem("calloutID", 1);
      setPage((prev) => prev + 1);
    }
  };

  const handleCallOutNextPrevBtn = (id) => {
    if (id <= 5) {
      setCalloutID((prev) => {
        localStorage.setItem("calloutID", prev - 1);
        return prev - 1;
      });
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        <div>
          <Navbar />
        </div>
        <div className={style.content}>
          <div className={style.imageWrapper}>
            <img src={image} alt="" />
          </div>
          <div className={style.options}>
            <button>Move Image</button>
            <button>Crop bottom right</button>
            <button>Crop top left</button>
          </div>
          <div className={style.saveBtn}>Save</div>

          <div className={style.editOptions}>
            <div className={style.option}>Layers</div>
            <div className={style.option}>Foreground</div>
            <div className={style.option}>Chroma Layer</div>
            <div className={style.option}>Background</div>
          </div>
        </div>
        <div className={style.footer}>
          <button>Reset</button>
          <button onClick={handlePage}>Close</button>
        </div>
        {calloutID === 1 && (
          <Callout
            data={calloutData1}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 2 && (
          <Callout
            data={calloutData2}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 3 && (
          <Callout
            data={calloutData3}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 4 && (
          <Callout
            data={calloutData4}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
        {calloutID === 5 && (
          <Callout
            data={calloutData5}
            onClick={handleCallOutNextNextBtn}
            onBackClick={handleCallOutNextPrevBtn}
          />
        )}
      </div>
    </>
  );
};
