import React from "react";
import style from "../../style/VideoComplete.module.css";
import close from "../../assets/Group 355.png";
import complete from "../../assets/Group 356.png";
import { ROUTES } from "../../pages/Routes";
import { Link } from "react-router-dom";
const WorkflowComplete = ({ resetFun }) => {
  return (
    <>
      <div
        className={style.videoOverlay}
        // onClick={handleModalClosed}
      ></div>
      <div className={style.Content}>
        {/* <nav>
                    <img src={close} alt="" onClick={handleModalClosed} />
                </nav> */}
        <div className={style.header}>
          <h1>Tutorial Completed</h1>
        </div>
        <div className={style.completeLogo}>
          <img src={complete} alt="" />
        </div>
        <div className={style.stackFooter}>
          {/* <h1 onClick={resetFun}>See Again</h1> */}
          <Link to={ROUTES.Selection} className={style.backBtn}>
            BACK TO SELECTION
          </Link>
          <div
            onClick={resetFun}
            className={style.backBtn}
            style={{ minWidth: "13rem", textAlign: "center" }}
          >
            RESET
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkflowComplete;
